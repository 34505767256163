import { FC, useContext, useEffect  } from 'react'
import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenuData, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useState } from 'react'
import SwitchOrganizationController from '../../../../app/modules/auth/components/SwitchOrganization/SwitchOrganization.controller'
import { AuthContext } from '../../../../app/context/auth/auth.context'
import { Drawer } from 'antd'
import Notification from '../../../partials/widgets/notify/Notification'
import axios from 'axios'
import { HttpService } from '../../../../app/services/http.services'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const { userName, userEmail } = useContext(AuthContext);
  const { config } = useLayout()
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);

  const [unreadCount, setUnreadCount] = useState(0);
  const [isDeviceNotificationClicked, setIsDeviceNotificationClicked] = useState(false);

  // Fetch unread count
  useEffect(() => {
    const fetchUnreadCount = async () => {
      HttpService.get(`auth/notification/total/unread`)
            .then(response => {
              setUnreadCount(response.data.unread_count);
              console.log((response.data.unread_count));
            })
            .catch(error => {
              console.error('Error fetching user data:', error);
            });
    };

    fetchUnreadCount();
  }, []);

  return (
    <div className='app-navbar flex-shrink-0'>
      <Notification  />
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
          <SwitchOrganizationController />
        </div>
      </div> */}


      {/* on click the red color doesnot goes away */}
      {/* <div className={clsx("app-navbar-item", itemClass)} style={{ position: "relative" }}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          className={clsx(btnClass, { "text-danger": unreadCount > 0 })}
          onClick={() => setIsNotificationClicked(true)}
        >
          <KTIcon
            iconName="notification"
            className={clsx(btnIconClass, { "text-danger": unreadCount > 0 })}
          />
          {unreadCount > 0 && (
            <span className="notification-badge">
              {unreadCount}
            </span>
          )}
        </div>
        <HeaderNotificationsMenuData
          isNotificationClicked={isNotificationClicked}
          setIsNotificationClicked={setIsNotificationClicked}
        />
      </div> */}

      {/* on first click the red color goes away but drawer doesnot open, drawer opens on second click */}
      <div className={clsx("app-navbar-item", itemClass)} style={{ position: "relative" }}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          className={clsx(btnClass, { "text-danger": unreadCount > 0 })}
          onClick={() => {
            setIsNotificationClicked(true); // Open the notification menu
            setUnreadCount(0); // Reset unread count (or add a condition if needed)
          }}
        >
          <KTIcon
            iconName="notification"
            className={clsx(btnIconClass, {
              "text-muted": unreadCount === 0, // Change class if no unread notifications
              "text-danger": unreadCount > 0, // Highlight if there are unread notifications
            })}
          />
          {unreadCount > 0 && (
            <span className="notification-badge">
              {unreadCount}
            </span>
          )}
        </div>
        <HeaderNotificationsMenuData
          isNotificationClicked={isNotificationClicked}
          setIsNotificationClicked={setIsNotificationClicked}
        />
      </div>


      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle' onClick={showDrawer}>
          <KTIcon iconName='message-text-2' className={btnIconClass}  />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' /> */}
          <KTIcon iconName='user' className={btnIconClass} />
          {' '}{userName}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }
