
import _FackApi from "./Fack.api";
import _OauthApi from "./Oauth.api";
// SETTING
import _ResourceApi from "./Setting/Resource.api";
import _ScopeApi from "./Setting/Scope.api";
import _RoleApi from "./Setting/Role.api";
import _GroupApi from "./Setting/Group.api";
import _OrganizationApi from "./Setting/Organization.api";
import _OrganogramApi from "./Setting/Organogram.api";
import _UserApi from "./Setting/User.api";
import _PermissionApi from "./Setting/Permission.api";
import _ParameterApiApi from "./Setting/Parameter.api";
import _UnitApi from "./Setting/Unit.api";
import _IconApi from "./Setting/Icon.api";
import _GatewayTypeApi from "./Setting/GatewayType.api";
import _GatewayEnrollmentApi from "./Setting/GatewayEnrollment.api";
import _DeviceCategoryApi from "./Setting/DeviceCategory.api";
import _DeviceSubCategoryApi from "./Setting/DeviceSubCategory.api";
import _DeviceApi from "./Setting/Device.api";
import _ReportApi from "./Setting/Report.api";
import _CommandApi from "./Setting/Command.api";
import _DashboardApi from "./Setting/Dashboard.api";
import _DeviceSettingApi from "./Setting/DeviceSetting.api";
import _NotificationApi from "./Setting/Notification.api";

export const FackApi = new _FackApi();
export const OauthApi = new _OauthApi();
// SETTING
export const ResourceApi = new _ResourceApi();
export const ScopeApi = new _ScopeApi();
export const RoleApi = new _RoleApi();
export const GroupApi = new _GroupApi();
export const OrganizationApi = new _OrganizationApi();
export const OrganogramApi = new _OrganogramApi();
export const UserApi = new _UserApi();
export const PermissionApi = new _PermissionApi();
export const ParameterApi = new _ParameterApiApi();
export const UnitApi = new _UnitApi();
export const IconApi = new _IconApi();
export const GatewayTypeApi = new _GatewayTypeApi();
export const GatewayEnrollmentApi = new _GatewayEnrollmentApi();
export const DeviceCategoryApi = new _DeviceCategoryApi();
export const DeviceSubCategoryApi = new _DeviceSubCategoryApi();
export const DeviceApi = new _DeviceApi();
export const ReportApi = new _ReportApi();
export const CommandApi = new _CommandApi();  
export const DashboardApi = new _DashboardApi();  
export const DeviceSettingApi = new _DeviceSettingApi();  
export const NotificationApi = new _NotificationApi();  