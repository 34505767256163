import { Message as _Message } from "./AlertUtils";
import { Notification as _Notification } from "./AlertUtils";
import { AntModal as _AntModal } from "./AlertUtils";
import _DateTimeUtils from "./DateTimeUtils";
import _CommonUtils from "./CommonUtils";
import _ObjectUtils from "./ObjectUtils";
import _NumberUtils from "./NumberUtils";
import _MathUtils from "./MathUtils";


export const Message = new _Message();
export const Notification = new _Notification();
export const AntModal = new _AntModal();
export const DateTimeUtils = new _DateTimeUtils();
export const CommonUtils = new _CommonUtils();
export const ObjectUtils = new _ObjectUtils();
export const NumberUtils = new _NumberUtils();
export const MathUtils = new _MathUtils();

