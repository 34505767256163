import { FC, useContext } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  CustomTablesWidget5,
  CardsWidget20,
  ChartsWidget3,
} from '../../../_metronic/partials/widgets'
import DeviceListAdmin from '../../modules/ems/components/DeviceAdminList/List/DeviceList.controller'
import DeviceListController from '../../../_metronic/partials/widgets/tables/List/DeviceList.controller'
import { AuthContext } from '../../context/auth/auth.context'

const DashboardPage: FC<any> = props => {
  // Destructure the context values
  const { userId, organizationId } = useContext(AuthContext)
  console.log(userId)

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-12 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className=' mb-5 mb-xl-10 h-md-50'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CustomTablesWidget5 className='mb-5 mb-xl-10 h-md-50' />
        </div>
        <div className='col-xxl-9'>
          <ChartsWidget3 className='' />
        </div>
        <div className="col-xxl-12">
          <DeviceListController />
        </div>
        {/* Render DeviceListAdmin only if organizationId is 1 */}
        {userId === 1 && (
          <div className="col-xxl-12">
            <DeviceListAdmin />
          </div>
        )}
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
