import { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import { NotificationApi } from '../../../../app/api';

const HeaderNotificationsMenuData: FC<any> = (props) => {
    const payload = useRef<any>({});

    const { isNotificationClicked, setIsNotificationClicked } = props;
    const [notification, setNotifications] = useState<any>([]);

    useEffect(() => {
        if (isNotificationClicked) {
            handlePayload();
            setIsNotificationClicked(false);
            fetchNotification();
            markAllAsRead(); // Automatically mark all notifications as read
        }
    }, [isNotificationClicked]);

    const fetchNotification = () => {
        NotificationApi.list(payload.current)
            .then((response) => {
                setNotifications(response.data.results);
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });
    };

    const markAllAsRead = () => {
        NotificationApi.makeAllRead()
            .then(() => {
                console.log('All notifications marked as read');
            })
            .catch((error) => {
                console.error('Error marking notifications as read:', error);
            });
    };

    const handlePayload = (): void => {
        payload.current = {
            '$select': '',
            '$search': '',
            '$filter': '',
            '$queryParams': '',
            '$expand': '',
            '$orderby': 'id desc',
            '$top': 10,
            '$skip': 0,
        };
    };

    return (
        <div
            className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
            data-kt-menu="true"
        >
            <div
                className="d-flex flex-column bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
            >
                <h3 className="text-white fw-bold px-9 mt-10 mb-6">
                    Events <span className="fs-8 opacity-75 ps-3"></span>
                </h3>

                <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
                    <li className="nav-item">
                        <a
                            className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
                            data-bs-toggle="tab"
                            href="#kt_topbar_notifications_1"
                        >
                            Alerts
                        </a>
                    </li>
                </ul>
            </div>

            <div className="tab-content">
                <div className="tab-pane fade show active" id="kt_topbar_notifications_1" role="tabpanel">
                    <div className="scroll-y mh-325px my-5 px-8">
                        {notification &&
                        notification.map((item: any, index: number) => (
                            <div key={`alert${index}`} className="d-flex flex-stack py-4">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-35px me-4">
                                <span className={clsx('symbol-label', `bg-light-warning`)}>
                                    <KTIcon iconName="information-5" className="fs-2 text-warning" />
                                </span>
                                </div>

                                <div className="mb-0 me-2">
                                <a className="fs-6 text-gray-800 text-hover-primary fw-bolder">
                                    {item.title}
                                </a>
                                <div className="text-gray-400 fs-7">{item.description}</div>
                                </div>
                            </div>

                            <span className="badge badge-light fs-8">{item.time}</span>
                            </div>
                        ))}
                    </div>

                    <div className="py-3 text-center border-top">
                        <Link
                            to="/admin/report/notification"
                            className="btn btn-color-gray-600 btn-active-color-primary"
                        >
                        View All <KTIcon iconName="arrow-right" className="fs-5" />
                            </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { HeaderNotificationsMenuData };
