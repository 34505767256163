// export const DEV = process.env.NODE_ENV === 'development';
export const ENV_PROD = false;
export const ENV_DEV = true;
export const ENV_TEST = false;
export const LOG_ENABLED = true;

// Local Server
export const BASE_URL = process.env.REACT_APP_BASE_URL;
// export const API_SERVER_URL = 'http://api.ems-laravel.test/';
export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;
// export const API_SERVER_URL = 'http://13.251.135.12:8081/';
// export const API_SERVER_URL = 'http://3.1.246.183/backend/';
// export const API_SERVER_URL = 'https://ems.btracsolutions.com/backend/';

export const SERVER_PREFIX = "auth";