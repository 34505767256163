import React, { FC } from 'react'
import { Button, Input, Select, Form } from 'antd';
import { DeviceAction } from "../Actions/Device.actions";
import { Col, Row } from 'react-bootstrap';
import CreateAction from '../../../../../components/Actions/CreateAction';
import BulkAction from '../../../../../components/Actions/BulkAction';
import { RefreshIcon, ResetIcon } from '../../../../../../_metronic/assets/images/icon/svg';
import OrganizationOrganogramDependentSelect from '../../../../../components/Dropdown/Dependent/OrganizationOrganogramDependentSelect';
import OrganizationSelect from '../../../../../components/Dropdown/OrganizationSelect';
import GatewayTypeSelect from '../../../../../components/Dropdown/GatewayTypeSelect';
import OrganogramSelect from '../../../../../components/Dropdown/OrganogramSelect';

const DeviceListFilter: FC<any> = props => {
    const { Search } = Input;
    const { Option } = Select;
    const { filters, handleOnChanged, handleCallbackFunc, formRef } = props;

    return (
        <div className='p-6'>
            <Row gutter={[16, 16]}>
                <Col md={6} xs={12}>
                    <div className='card card-header p-0 pb-3' style={{ minHeight: "0px" }}>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bold fs-3 mb-1'>Equipments last data list</span>
                        </h3>
                    </div>
                </Col>
                {/* <Col md={6} xs={12}>
                    <CreateAction actionItem={DeviceAction.COMMON_ACTION.CREATE} handleCallbackFunc={handleCallbackFunc} />
                </Col> */}
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={3}>
                    <Form.Item name="search">
                        <Search placeholder="Search" onSearch={(value) => handleOnChanged('search', value)} />
                    </Form.Item>
                </Col>

                <Col span={3}>
                    <Form.Item name="organization_id" label="Organization">
                        <OrganizationSelect
                            organizationId={filters.organization_id}
                            placeholder={"Select Organization"}
                            onChange={(value) => handleOnChanged('filter_organization', value)}
                        /> 
                    </Form.Item>
                </Col>

                <Col span={3}>
                    <Form.Item name="organogram_id" label="Organogram">
                        <OrganogramSelect
                            organizationId={filters.organization_id}
                            organogramId={filters.organogram_id}
                            placeholder={"Select Organogram"}
                            onChange={(value) => handleOnChanged('filter_organogarm', value)}
                        /> 
                    </Form.Item>
                </Col>

                <Col span={3}>
                    <div className="d-flex justify-content-end">
                        <button
                            // disabled={isLoading}
                            title='Reset'
                            type='button'
                            className='btn btn-sm btn-light-primary me-3'
                            onClick={(event) => handleCallbackFunc(null, 'resetListing')}
                        >
                            <ResetIcon rev={''} />
                        </button>

                        <button
                            title='Refresh'
                            type='button'
                            className='btn btn-sm btn-light-primary me-3'
                            onClick={(event) => handleCallbackFunc(null, 'reloadListing')}
                        >
                            <RefreshIcon rev={''} />
                        </button>

                        {/* <BulkAction bulkAction={DeviceAction.BULK_ACTION} handleCallbackFunc={handleCallbackFunc} /> */}
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default React.memo(DeviceListFilter);