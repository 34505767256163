import React, { FC } from 'react'
import { CommonUtils, DateTimeUtils } from '../../../../../utils';
import ListItemAction from '../../../../../components/Actions/ListItemAction';
import ViewAction from '../../../../../components/Actions/ViewAction';
import AntTable from '../../../../../components/Table/AntTable';
import { DeviceAction } from '../Actions/Device.actions';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

const DeviceListing: FC<any> = props => {
    const { loading, listData, selectedRowKeys, onChangeSwitchToggle, handleOnChanged, handleTableChange, handleCallbackFunc } = props;
    const columns = [
        {
            dataIndex: "name",
            key: 'name',
            title: "Device Name",
            sorter: true,
            width: '20%',
            // render: (text: string, record: any, index: number) => <ViewAction entityId={record.id} actionItem={DeviceAction.COMMON_ACTION.VIEW} defaultViewText={text} handleCallbackFunc={handleCallbackFunc}><span className="grid-row-view-action cursor-pointer fw-bolder">{text}</span></ViewAction>,
        },
        {
            dataIndex: "organization_name",
            key: 'organization_name',
            title: "Organization",
            sorter: false,
            width: '15%',
        },
        {
            dataIndex: "organogram_name_en",
            key: 'organogram_name_en',
            title: "Organogram",
            sorter: false,
            width: '15%',
        },
        {
            dataIndex: "time",
            key: "time",
            title: "Differance",
            sorter: false,
            width: '20%',
            render: (text: number, record: any, index: number) => {
                const currentTime = dayjs();
                const updatedTime = record.latest_stream_data ? dayjs(record.latest_stream_data.updated_at) : null;
        
                if (!updatedTime) return "";
        
                const timeDiff = currentTime.diff(updatedTime, 'day');

                let color = '';
                if (timeDiff > 30) {
                    color = 'red';
                } else if (timeDiff > 7) {
                    color = 'orange';
                } else if (timeDiff > 2) {
                    color = 'blue';
                }
        
                return (
                    <span style={{ color }}>
                        {updatedTime.from(currentTime)}
                    </span>
                );
            }
        },
        {
            dataIndex: "updated_at",
            key: "updated_at",
            title: "Updated At",
            sorter: false,
            width: '20%',
            render: (text: number, record: any, index: number) => (
                record.latest_stream_data ? (
                    record.latest_stream_data.updated_at
                ) : ""
            )
        },
        
    ];

    return (
        <div className="px-6">
            <AntTable
                className="table-layout"
                rowSelection={true}
                rowSelectionPermission="auth:example:multiSelect"
                selectedRowKeys={selectedRowKeys}
                dataSource={listData}
                columns={columns}
                loading={loading}
                handleOnChanged={handleOnChanged}
                onChange={handleTableChange}
            />
        </div>
    );
}

export default React.memo(DeviceListing);